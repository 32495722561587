import { Box, Button, Divider, Modal, Card, CircularProgress, Typography, CardContent, ModalDialog, ModalClose, DialogTitle, DialogContent, Table, FormControl, FormLabel, Input, Tooltip } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";

import { approveAllResponders, removeTaggedResponder, tagResponderToVisit, useFetchVisitResponders } from "../helpers/VisitResponder";
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Search } from '@mui/icons-material';
import { useFetchOrgUsers } from "../helpers/UserAccounts";
import { convertTime } from "../helpers/TimeConversionHelper";
import { AlertContext } from "./AlertContext";
import { getUserID } from "../helpers/AuthBarrier";
import { removeTaggedExecutive, tagExecutiveToVisit, useFetchVisitExecutives } from "../helpers/VisitExecutives";
import ApproveAllResponders from "./modals/ApproveAllResponders";

function filter_and_sort(users, filter) {
    const matchedUsers = []
    const wildcardUsers = []

    for (const user of users) {
        if (user["full_name"].toLowerCase().startsWith(filter.toLowerCase())) {
            matchedUsers.push(user);
        } else if (user["full_name"].toLowerCase().includes(filter.toLowerCase())) {
            wildcardUsers.push(user);
        }
    }
    return matchedUsers.concat(wildcardUsers);
}

function EmptyCard({type}) {
    return <Card size="lg" sx={{ width: "20%"}}>
        <CardContent orientation="horizontal" sx={{alignItems: "center"}}>
            <PersonIcon sx={{ fontSize: "40px"}}/>
            <CardContent>
                <Typography level="h5">{type === "individual" ? "No responders tagged" : "No executives tagged"}</Typography>
            </CardContent>
        </CardContent>
    </Card>
}


function ResponderCard({visit_responder, creator, visitID, handleRefresh, visit_status}) {

    return <Card size="lg" sx={{ width: "20%", mx: 2}} key={visit_responder['id']}>
        <CardContent orientation="horizontal" sx={{alignItems: "center"}}>
            <SensorOccupiedIcon sx={{ fontSize: "40px"}}/>
            <CardContent>
                <Typography level="h4">{visit_responder["user_account"]["full_name"]}</Typography>
                <Typography> Responder </Typography>
            </CardContent>
        </CardContent>
        <CardContent>
            <Typography> Tagged at: {convertTime(visit_responder['created_at'])} </Typography>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography level="h4" sx={{ color: (visit_responder['status'] === "pending" ? "var(--dark-yellow)" : "green")}}> 
                    {visit_responder['status'].toUpperCase()}
                </Typography>
                {creator && <Button onClick={async () => { 
                    const response = await removeTaggedResponder(visitID, visit_responder['id'])
                    if (response) {
                        handleRefresh();
                    }
                    }} 
                    startDecorator={<CloseIcon />}
                    sx={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "var(--red-button)",
                        '&:hover': {
                            backgroundColor: "var(--red-button-hover)",
                        },
                    }} disabled={visit_status !== "draft"}> Remove </Button>}
            </Box>
        </CardContent>
    </Card>
}


function ExecutiveCard({visit_responder, creator, visitID, handleRefresh, visit_status}) {

    return <Card size="lg" sx={{ width: "20%", mx: 2}} key={visit_responder['id']}>
        <CardContent orientation="horizontal" sx={{alignItems: "center"}}>
            <SensorOccupiedIcon sx={{ fontSize: "40px"}}/>
            <CardContent>
                <Typography level="h4">{visit_responder["user_account"]["full_name"]}</Typography>
                <Typography> Executive </Typography>
            </CardContent>
        </CardContent>
        <CardContent>
            <Typography> Tagged at: {convertTime(visit_responder['created_at'])} </Typography>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography level="h4" sx={{ color: (visit_responder['status'] === "pending" ? "var(--dark-yellow)" : visit_responder['status'] === "denied" ? "red" : "green")}}> 
                    {visit_responder['status'].toUpperCase()}
                </Typography>
                {creator && <Button onClick={async () => {
                    const response = await removeTaggedExecutive(visitID, visit_responder['id'])
                    if (response) {
                        handleRefresh();
                    }
                    }} 
                    startDecorator={<CloseIcon />}
                    sx={{
                        display: "flex",
                        color: "white",
                        backgroundColor: "var(--red-button)",
                        '&:hover': {
                            backgroundColor: "var(--red-button-hover)",
                        },
                    }} disabled={visit_status !== "draft"}> Remove </Button>}
            </Box>
        </CardContent>
    </Card>
}

function AddUsersModal({ open, onClose, userType = null, taggedUsers, visitID, handleRefresh, handleRefreshExecutives}) {
    const { addAlert } = useContext(AlertContext)
    const { usersLoaded, loadingUsers, errorUsers } = useFetchOrgUsers(userType);
    const [ filter, setFilter ] = useState(null);
    const [ users, setUsers ] = useState(null);

    const tagUser = async (user_account_id) => {
        let response;
        if (userType === "individual") {
            response = await tagResponderToVisit(visitID, user_account_id);
        } else {
            response = await tagExecutiveToVisit(visitID, user_account_id);
        }
        if (response && userType === "individual") {
            handleRefresh();
            addAlert("Tagged!", "success")
        } else if (response && userType === "organization_admin") {
            handleRefreshExecutives();
            addAlert("Tagged!", "success")
        } else {
            addAlert("Couldn't tag user", "danger")
        }
    }

    useEffect(() => {
        if (filter && usersLoaded) {
            setUsers(filter_and_sort(usersLoaded, filter))
        } else {
            setUsers(usersLoaded)
        }
    }, [filter, usersLoaded])

    return <Modal open={open} onClose={onClose}>
        <ModalDialog>
            <ModalClose />
            <DialogTitle> Attach {userType === "individual" ? "a responder" : "an executive"} </DialogTitle>
            <DialogContent>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search by full name</FormLabel>
                    <Input size="sm" placeholder="Search" startDecorator={<Search />} sx={{p: "8px"}} onChange={ event => setFilter(event.target.value) }/>
                </FormControl>
                <Table aria-label="users table">
                <thead>
                    <tr>
                        <th style={{ width: '25%' }}>Full Name</th>
                        <th style={{ width: '15%' }}> Role </th>
                        <th style={{ width: '15%' }}> Title </th>
                        <th style={{ width: '25%' }}> Created at</th>
                        <th style={{ width: '20%' }}> Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map((item, index) => {
                        if (getUserID() === item["id"]) {
                            return;
                        }
                        return <tr key={item["id"]}>
                            <td> {item["full_name"]} </td>
                            <td> {item["type"] === "individual" ? "Responder" : "Executive"} </td>
                            <td> {item["job_title"]} </td>
                            <td> {convertTime(item["created_at"])} </td>
                            <td> <Button startDecorator={<AddIcon />} sx={{
                                    display: "flex",
                                    color: "white",
                                    backgroundColor: "var(--dark-blue-button)",
                                    '&:hover': {
                                        backgroundColor: "var(--dark-blue-button-hover)",
                                    },
                                }} onClick={() => {tagUser(item["id"])}} disabled={taggedUsers.includes(item["id"])}>Tag</Button></td>
                        </tr>
                    })}
                    {loadingUsers && <CircularProgress />}
                </tbody>
                </Table>
            </DialogContent>
        </ModalDialog>
    </Modal>
}

export function AttachUsersToVisit({visitID, creator_id, visit_status, setAllowedReview, setNoTagged, setIsExecTagged}) {
    const { addAlert } = useContext(AlertContext)
    const [ reload, setReload ] = useState(false);
    const [ reloadExecutives, setReloadExecutives ] = useState(false);
    const { responders, loadingResponders, errorResponders } = useFetchVisitResponders(visitID, reload);
    const { executives, loadingExecutives, errorExecutives } = useFetchVisitExecutives(visitID, reloadExecutives);
    const [ openAttachUser, setOpenAttachUser ] = useState(false);
    const [ modalUserType, setModalUserType ] = useState("individual");
    const [ approveAllRespondersModal, setApproveAllRespondersModal ] = useState(false);

    const retrieveIDs = (responders) => {
        const taggedUsers = []
        for (const item of responders) {
            taggedUsers.push(item['user_account_id'])
        }
        return taggedUsers
    }

    const handleRefresh = () => {
        setReload( prev => !prev)
    }

    const handleRefreshExecutives = () => {
        setReloadExecutives( prev => !prev );
    }

    const approve_all_responders = async () => {
        await approveAllResponders(visitID, responders).then(() => {
            addAlert("Approved all responders!", "success")
        }).catch(() => {
            addAlert("Couldn't approve pending responders", "danger")
        }).finally(() => {
            handleRefresh()
        })
    }

    const checkReviewAllowed = () => {
        for (const responder of responders) {
            if (responder["status"] !== "approved") {
                setAllowedReview(false);
                return;
            }
        }

        for (const executive of responders) {
            if (executive["status"] !== "approved") {
                setAllowedReview(false);
                return;
            }
        }
        setAllowedReview(true);
    }

    useEffect(() => {
        // Check responders to see if pushing to review is allowed
        responders && checkReviewAllowed();
        if (responders && executives) {
            if (responders.length > 0 && executives.length > 0) {
                setNoTagged(false);
            } else {
                setNoTagged(true);
            }
        }

        if (executives) {
            let execTagged = false;
            for (const executive of executives) {
                if (getUserID() === executive['user_account_id']) {
                    execTagged = true;
                }
            }
            setIsExecTagged(execTagged);
        }
    }, [responders, executives])

    useEffect(() => {
        handleRefresh();
        handleRefreshExecutives();
    }, [visit_status])

    return <Box>
        {responders && <AddUsersModal open={openAttachUser} onClose={() => { setOpenAttachUser(false) }} userType={modalUserType} visitID={visitID} taggedUsers={ modalUserType === "individual" ? retrieveIDs(responders) : retrieveIDs(executives)} handleRefresh={handleRefresh} handleRefreshExecutives={handleRefreshExecutives}/>}
        {responders && <ApproveAllResponders open={approveAllRespondersModal} onClose={() => {setApproveAllRespondersModal(false)}} submit={approve_all_responders}/>}
        <Divider sx={{my: 2}}/>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography level="h3"> Responders: </Typography>
            {getUserID() === creator_id && <Button 
                startDecorator={
                    <AddIcon />
                }
                sx={{
                    display: "flex",
                    '&:hover': {
                        backgroundColor: "var(--light-blue-button)",
                    },
                    }}
                onClick={() => { 
                    setModalUserType("individual");
                    setOpenAttachUser(true);
                }}
                disabled={visit_status != "draft"}
                >
                    Tag a new responder
            </Button>}
            {getUserID() === creator_id && <Button 
                startDecorator={
                    loadingResponders ? <CircularProgress /> : <PublishedWithChangesIcon />
                }
                sx={{
                    display: "flex",
                    color: "white",
                    backgroundColor: "var(--dark-blue-button)",
                    '&:hover': {
                        backgroundColor: "var(--dark-blue-button-hover)",
                    },
                    }}
                onClick={() => {
                    setApproveAllRespondersModal(true);
                }}
                disabled={visit_status != "prelim_check" || !responders || loadingResponders}
                >
                    Approve all responders
            </Button>}
        </Box>
        <Box sx={{ my: 2, display: "flex"}}>
            {/* Fetch current visit responders for the visit. Iterate through and display here. */}
            {errorResponders && <Typography> {errorResponders}</Typography>}
            {loadingResponders && <CircularProgress />}
            {responders && responders.map((item, index) => {
                return <ResponderCard visit_responder={item} key={index} creator={getUserID() === creator_id} visitID={visitID} handleRefresh={handleRefresh} visit_status={visit_status}/>
            })}
            {!responders && <EmptyCard type="individual"/>}
            {responders && responders.length === 0 && <EmptyCard type="individual"/>}
        </Box>
        <Divider sx={{my: 2}}/>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography level="h3"> Executives: </Typography>
            {getUserID() === creator_id && executives && <Button 
                startDecorator={
                    <AddIcon />
                }
                sx={{
                    display: "flex",
                    '&:hover': {
                        backgroundColor: "var(--light-blue-button)",
                    },
                    }}
                onClick={() => { 
                    setModalUserType("organization_admin");
                    setOpenAttachUser(true);
                }}
                disabled={executives.length > 1 || visit_status != "draft"}
                >
                    Tag a new executive
            </Button>}
            {executives && executives.length > 1 && getUserID() === creator_id && <Tooltip title="You can't tag more than one executive per report.">
                <InfoOutlinedIcon />
            </Tooltip>}
        </Box>

        <Box sx={{ my: 2, display: "flex"}}>
            {/* Fetch current visit executives for the visit. Iterate through and display here. */}
            {errorExecutives && <Typography> {errorExecutives}</Typography>}
            {loadingExecutives && <CircularProgress />}
            {executives && executives.map((item, index) => {
                return <ExecutiveCard visit_responder={item} key={index} creator={getUserID() === creator_id} visitID={visitID} handleRefresh={handleRefreshExecutives} visit_status={visit_status}/>
            })}
            {!executives && <EmptyCard type="organization_admin"/>}
            {executives && executives.length === 0 && <EmptyCard type="organization_admin"/>}
        </Box>
        <Divider sx={{my: 2}}/>
    </Box>
}